import axios from "axios";
import Cookies from "js-cookie";
import store from "@/stores";
import app from "../main";

const API = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
});
API.defaults.headers.common["Accept"] = "application/json";

API.interceptors.request.use(
  (config) => {
    const token =
      store.getters["auth/token"] ||
      Cookies.get("token") ||
      localStorage.getItem("token");

    config.headers.Authorization = `Bearer ${token}`;
    config.headers.common["Accept"] = "application/json";

    app.$Progress.start();
    return config;
  },
  (error) => {
    app.$Progress.finish();
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    app.$Progress.finish();
    return response;
  },
  (error) => {
    app.$Progress.fail();
    return Promise.reject(error);
  }
);

export default API;
