import { mapGetters } from "vuex";
import swal from "sweetalert";
import moment from "moment";
import removeNullProperties from "../../utils";

export default {
    name: "CreateVoucherCode",

    computed: {
        ...mapGetters("vouchercode", ["voucherCode", "errors", "loadingFlag"]),
    },

    data() {
        return {
            input: {
                code: null,
                coupon_id: null,
                max_redeem: null,
                last_redeem_date: moment().add(1, 'month').format('YYYY-MM-DD').toString(),
            }
        }
    },

    methods: {
        async onSubmit() {
            await this.$store.dispatch("vouchercode/createVoucherCode", {
              input: removeNullProperties(this.input),
              onSuccess: () => {
                swal(
                  "Voucher Code Created Successfully",
                  "success"
                );
                this.$router.push({ name: "voucher-codes" });
              },
              onFailed: () => {
                swal(
                  "Voucher Code Creation Failed",
                  "Please fill in the missing / incorrect details and try again.",
                  "error"
                );
              },
            });
        },
    }
}