<template>
  <!-- Topbar -->
  <fragment>
    <span class="md-title" style="flex: 1">&nbsp;</span>
    <md-menu md-direction="bottom-start" md-size="medium" md-align-trigger>
      <md-button md-menu-trigger>Administrator</md-button>

      <md-menu-content>
        <md-menu-item to="/settings">
          <md-icon>settings</md-icon>
          <span>Settings</span>
        </md-menu-item>
        <md-menu-item
          @click="
            () => {
              $store.dispatch('auth/logout');
            }
          "
        >
          <md-icon>logout</md-icon>
          <span>Logout</span>
        </md-menu-item>
      </md-menu-content>
    </md-menu>
  </fragment>
  <!-- End of Topbar -->
</template>

<script>
export default {
  name: "ToolbarHeader",
};
</script>

<style lang="scss" scoped></style>
