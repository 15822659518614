import { mapGetters } from "vuex";
import swal from "sweetalert";
import moment from "moment";
import removeNullProperties from "../../utils";

export default {
    name: "CreateCoupon",

    computed: {
        ...mapGetters("coupon", ["coupon", "errors", "loadingFlag"]),
    },

    data() {
        return {
            input: {
                stripe_coupon_id: null,
                amount_off: null,
                percent_off: null,
                max_redeem: null,
                last_redeem_date: moment().add(1, 'year').format('YYYY-MM-DD').toString(),
                is_annual: true,
            }
        }
    },

    methods: {
        async onSubmit() {
            await this.$store.dispatch("coupon/createCoupon", {
              input: removeNullProperties(this.input),
              onSuccess: () => {
                swal(
                  "Coupon Created Successfully",
                  "success"
                );
                this.$router.push({ name: "coupons" });
              },
              onFailed: () => {
                swal(
                  "Coupon Creation Failed",
                  "Please fill in the missing / incorrect details and try again.",
                  "error"
                );
              },
            });
        },
    }
}