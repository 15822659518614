import { mapGetters } from "vuex";
import swal from "sweetalert";

export default {
  name: "CreateRetailer",

  computed: {
    ...mapGetters("retailer", ["retailer", "errors", "loadingFlag"]),
  },

  data() {
    return {
      input: {
        company: "",
        status: "",
      },
    };
  },

  methods: {
    async onSubmit() {
      await this.$store.dispatch("retailer/createRetailer", {
        input: this.input,
        onSuccess: () => {
          swal(
            "Retailer Created Successfully",
            "Congratulations on another Lifebox retailer, Lifebox Admin.",
            "success"
          );
          this.$router.push({ name: "retailers" });
        },
        onFailed: () => {
          swal(
            "Retailer Creation Failed",
            "Please fill in the missing / incorrect details and try again.",
            "error"
          );
        },
      });
    },

    async onCancel() {
      const message = {
        title: "Are you sure you want to cancel editing this retailer?",
        text:
          "All changes made to this retailer will not be saved after this confirmation.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      };

      if (await swal(message)) {
        await this.$store.dispatch("retailer/clearRetailer");
        this.$router.push({ name: "retailers" });
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const retailerID = to.params.retailerID;
      if (retailerID) {
        next(
          async (vm) =>
            await vm.$store.dispatch("retailer/setRetailer", retailerID)
        );
      } else {
        next();
      }
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.$store.dispatch("retailer/clearRetailer");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteLeave(to, from, next) {
    try {
      await this.$store.dispatch("retailer/clearRetailer");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },
};
