import API from "@/stores/API";
import moment from "moment";
import { set, isEmpty } from "lodash";

const SET_POPULATED_VOUCHER_CODES = "SET_POPULATED_VOUCHER_CODES";
const SET_LOADING_FLAG = "SET_LOADING_FLAG";
const CLEAR_LOADING_FLAG = "CLEAR_LOADING_FLAG";

const SET_FORM_ERRORS = "SET_FORM_ERRORS";
const CLEAR_FORM_ERRORS = "CLEAR_FORM_ERRORS";

const INPUT_CHANGED = "INPUT_CHANGED";

/**
 * Vuex state for auth module
 * @type {Object}
 */
const state = {
    populatedVoucherCodes: {},
    voucherCode: {},
    errors: {},
    loadingFlag: false,
    dataTableColumns: [
        "id",
        "code",
        "stripe_id",
        "amount_off",
        "percent_off",
        "max_redeem",
        "created_at",
    ],
};

/**
 * Vuex mutations for auth module
 * @type {Object}
 */
const mutations = {
    [SET_POPULATED_VOUCHER_CODES]: (state, payload) => {
        state.populatedVoucherCodes = payload;
    },

    [SET_FORM_ERRORS]: (state, payload) => {
        state.errors = payload;
    },

    [CLEAR_FORM_ERRORS]: (state) => {
        state.errors = {};
    },

    [SET_LOADING_FLAG]: (state, payload) => {
        state.loadingFlag = payload;
    },

    [CLEAR_LOADING_FLAG]: (state) => {
        state.loadingFlag = {};
    },

    [INPUT_CHANGED]: (state, { key, value }) => {
        const stack = Object.assign({}, state.retailer);
        if (key) set(stack, key, value);
        state.retailer = stack;
    },
};

/**
 * Vuex actions for auth module
 * @type {Object}
 */
const actions = {
    async populateVoucherCodes({ commit }, payload) {
        try {
            commit(SET_LOADING_FLAG, true);
            const { data } = await API.get("/api/voucher-code", {
                params: payload,
            });
            commit(SET_POPULATED_VOUCHER_CODES, data);
            commit(SET_LOADING_FLAG, false);
        } catch (e) {
            console.error(e);
        }
    },

    inputChanged({ commit }, payload) {
        commit(INPUT_CHANGED, payload);
    },

    async createVoucherCode({ commit }, { input, onSuccess, onFailed }) {
        commit(SET_LOADING_FLAG, true);

        try {
            const { data } = await API.post("/api/voucher-code", { ...input });
            if (onSuccess instanceof Function) onSuccess(data);
        } catch (error) {
            if (error.response.status === 422)
                commit(SET_FORM_ERRORS, error.response.data.errors);
            if (onFailed instanceof Function) onFailed(error);
        }

        commit(SET_LOADING_FLAG, false);
    },
};

/**
 * Vuex getters for voucherCode module
 * @type {Object}
 */
const getters = {
    populatedVoucherCodes: ({ populatedVoucherCodes }) => {
        const { data } = populatedVoucherCodes;
        const items = [];

        if (!isEmpty(data)) {
            data.forEach((voucherCode) => {
                items.push([
                    voucherCode.id,
                    voucherCode.code,
                    voucherCode.stripe_id,
                    voucherCode.amount_off,
                    voucherCode.percent_off,
                    voucherCode.max_redeem,
                    moment(voucherCode.created_at).format("dddd, MMMM D, YYYY - h:mm A"),
                ]);
            });
        }

        return items;
    },
    populatedVoucherCodesRaw: ({ populatedVoucherCodes }) => {
        const { data } = populatedVoucherCodes;
        const items = [];

        if (!isEmpty(data)) {
            data.forEach((voucherCode) => {
                items.push({
                    id: voucherCode.id,
                    code: voucherCode.code,
                    stripe_id: voucherCode.coupon.stripe_id,
                    amount_off: voucherCode.coupon.amount_off,
                    percent_off: voucherCode.coupon.percent_off,
                    max_redeem: voucherCode.max_redeem,
                    created_at: moment(voucherCode.created_at).format("dddd, MMMM D, YYYY - h:mm A"),
                });
            });
        }

        return items;
    },
    populatedVoucherCodesCount: (state) => state.populatedVoucherCodes.total,
    dataTableColumns: (state) => state.dataTableColumns,
    voucherCode: (state) => state.voucherCode,
    errors: (state) => state.errors,
    loadingFlag: (state) => state.loadingFlag,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
