import Vue from "vue";
import store from "@/stores";
import { GuestMiddleware, AuthMiddleware } from "@/Middlewares";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [
    /**
     * Routes for guest pages
     */
    ...GuestMiddleware([
      {
        path: "/",
        name: "login",
        component: () => import("@/views/Login/Login.vue"),
      },
    ]),

    /**
     * Routes for authenticated pages
     */
    ...AuthMiddleware([
      {
        path: "",
        redirect: "/home",
      },
      {
        path: "/home",
        name: "home",
        meta: {
          title: "Dashboard",
        },
        component: () => import("@/views/Home"),
      },
      {
        path: "/users",
        name: "users",
        meta: {
          title: "Users",
        },
        component: () => import("@/views/Users/Users.vue"),
      },
      {
        path: "/retailers",
        name: "retailers",
        meta: {
          title: "Retailers",
        },
        component: () => import("@/views/Retailers/Retailers.vue"),
      },
      {
        path: "/coupons",
        name: "coupons",
        meta: {
          title: "Coupons",
        },
        component: () => import("@/views/Coupons/Coupons.vue"),
      },
      {
        path: "/voucher-codes",
        name: "voucher-codes",
        meta: {
          title: "Voucher Codes",
        },
        component: () => import("@/views/VoucherCodes/VoucherCodes.vue"),
      },
      {
        path: "/user-activities",
        name: "user-activities",
        meta: {
          title: "User Activities",
        },
        component: () => import("@/views/UserActivities/UserActivities.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        meta: {
          title: "Notifications",
        },
        component: () => import("@/views/Notifications"),
      },
      {
        path: "/settings",
        name: "settings",
        meta: {
          title: "Settings",
        },
        component: () => import("@/views/Settings"),
      },
      {
        path: "/users/update/:userID",
        name: "update-user",
        component: () => import("@/views/UpdateUser/UpdateUser.vue"),
      },
      {
        path: "/users/notify/:userID",
        name: "notify-user",
        component: () => import("@/views/NotifyUser/NotifyUser.vue"),
      },
      {
        path: "/users/create",
        name: "create-user",
        component: () => import("@/views/CreateUser/CreateUser.vue"),
      },
      {
        path: "/retailers/update/:retailerID",
        name: "update-retailer",
        component: () => import("@/views/UpdateRetailer/UpdateRetailer.vue"),
      },
      {
        path: "/retailers/create",
        name: "create-retailer",
        component: () => import("@/views/CreateRetailer/CreateRetailer.vue"),
      },
      {
        path: "/coupons/create",
        name: "create-coupon",
        component: () => import("@/views/CreateCoupon/CreateCoupon.vue"),
      },
      {
        path: "/voucher-codes/create",
        name: "create-voucher-code",
        component: () => import("@/views/CreateVoucherCode/CreateVoucherCode.vue"),
      },
    ]),
  ],
});

router.beforeEach(async (to, from, next) => {
  if (store.getters["auth/token"] && !store.getters["auth/user"]) {
    await store.dispatch("auth/fetchUser");
  }
  next();
});

export default router;
