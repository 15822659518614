<template>
  <AuthLayout>
    <div class="mt-5">
      <empty-result
        label="Oops!"
        description="Notification Page under construction. Sorry for the inconvenience"
      ></empty-result>
    </div>
  </AuthLayout>
</template>

<script>
export default {
  name: "Notifications",
};
</script>
