import { mapGetters } from "vuex";
import swal from "sweetalert";
import { map } from "lodash";

export default {
  name: "NotifyUser",

  computed: {
    ...mapGetters("user", [
      "user",
      "userStatuses",
      "userTypes",
      "errors",
      "loadingFlag",
    ]),
  },

  data() {
    return {
      pushTitle: "",
      pushMessage: "",
      devicesArr: [],
      selectedDevices: [],
      id: null,
      showLoading: true,
    };
  },

  watch: {
    user() {
      this.initData();

      if (this.user) {
        this.showLoading = false;
      }
    },
  },
  created() {
    this.showLoading = true;
  },
  mounted() {
    this.showLoading = true;
    this.initData();
  },

  methods: {
    initData() {
      this.id = this.user.id;
      this.devicesArr = map(this.user.pushtokens, (item) => {
        return {
          ...item,
          selected: false,
        };
      });
    },
    async onSubmit() {
      await this.$store.dispatch("user/sendUserPushNotification", {
        id: this.id,
        payload: {
          title: this.pushTitle,
          body: this.pushMessage,
        },
        onSuccess: () => {
          swal("Notification Sent", "", "success");
          this.pushTitle = "";
          this.pushMessage = "";
        },
        onFailed: () => {
          swal("Notification Sent Failed", "Please try again.", "error");
        },
      });
    },

    async onCancel() {
      this.$router.push({ name: "users" });
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const userID = to.params.userID;
      if (userID) {
        next(async (vm) => await vm.$store.dispatch("user/setUser", userID));
      } else {
        next();
      }
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.$store.dispatch("user/clearUser");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteLeave(to, from, next) {
    try {
      await this.$store.dispatch("user/clearUser");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },
};
