import store from "@/stores";

export const AuthMiddleware = (routes) => {
  return routes.map((route) => {
    return {
      ...route,
      beforeEnter: Guard(route, (to, from, next) => {
        const token = store.getters["auth/token"];
        if (!token) {
          next({ name: "login" });
        } else {
          next();
        }
      }),
    };
  });
};

export const GuestMiddleware = (routes) => {
  return routes.map((route) => {
    return {
      ...route,
      beforeEnter: Guard(route, (to, from, next) => {
        const token = store.getters["auth/token"];
        if (token) {
          next({ name: "home" });
        } else {
          next();
        }
      }),
    };
  });
};

function multiple(guards) {
  return (to, from, next) => {
    const stack = [].concat(guards);
    function another(args) {
      const guard = stack.pop();
      guard ? guard(to, from, another) : next(args);
    }
    another();
  };
}

function Guard(route, beforeEnter) {
  return multiple([route.beforeEnter].concat(beforeEnter));
}
