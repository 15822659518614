<template>
  <div class="text-center mt-5 mb-xl-5">
    <md-progress-spinner
      md-mode="indeterminate"
      :md-diameter="30"
      :md-stroke="3"
    ></md-progress-spinner>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>
