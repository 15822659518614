export default {
  data: () => ({
    loading: false,
    error: null,
    errors: {},
    form: {
      email: null,
      password: null,
    },
  }),

  methods: {
    async onSubmit() {
      this.loading = true;
      this.error = null;
      this.errors = {};
      console.log("this.form: ", this.form);

      try {
        const { loggedIn } = await this.$store.dispatch(
          "auth/login",
          this.form
        );
        if (loggedIn) this.$router.push({ name: "home" });
      } catch (e) {
        if (e.response) {
          const { data } = e.response;
          this.errors = data.errors || {};
          this.error = !data.errors ? data.message : null;
          this.password = null;
        } else {
          this.$toast.info(
            "Please check your Internet connection",
            "We're Very Sorry",
            { position: "topRight" }
          );
        }
      }

      this.loading = false;
    },
  },
};
