import Vuex from "vuex";
import Vue from "vue";
import axios from "axios";

import auth from "@/stores/auth";
import user from "@/stores/user";
import retailer from "@/stores/retailer";
import coupon from "@/stores/coupon";
import vouchercode from "./vouchercode";
import useractivity from "./useractivity";

// import app from '@/main'

const allowedTypes = ["auth/SET_TOKEN", "auth/LOGOUT"];

const setRequestHeaders = (state) => {
  const { token } = state.auth;
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  axios.defaults.headers.common["Accept"] = "application/json";
};

const Plugins = {
  request: (store) => {
    setRequestHeaders(store.state);

    store.subscribe((mutation, state) => {
      if (allowedTypes.indexOf(mutation.type) !== -1) {
        setRequestHeaders(state);
      }
    });
  },
};

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    auth,
    user,
    retailer,
    coupon,
    vouchercode,
    useractivity,
  },
  plugins: [Plugins.request],
});

export default store;
