<template>
  <!-- Sidebar -->
  <div>
    <md-toolbar class="md-primary" md-elevation="0">
      <div class="sidebar-brand">
        <img src="@/assets/img/logos/logo.png" height="40" width="105" />
        <span class="sidebar-brand-text">Admin</span>
      </div>
    </md-toolbar>
    <md-list class="mt-4">
      <md-subheader> MAIN MENU</md-subheader>

      <md-list-item to="/home">
        <md-icon>home</md-icon>
        <span class="md-list-item-text">Home</span>
      </md-list-item>

      <md-list-item to="/users">
        <md-icon>attach_money</md-icon>
        <span class="md-list-item-text">Users</span>
      </md-list-item>

      <md-list-item to="/retailers">
        <md-icon>public</md-icon>
        <span class="md-list-item-text">Retailers</span>
      </md-list-item>

      <md-list-item to="/coupons">
        <md-icon>payments</md-icon>
        <span class="md-list-item-text">Coupons</span>
      </md-list-item>

      <md-list-item to="/voucher-codes">
        <md-icon>sell</md-icon>
        <span class="md-list-item-text">Voucher Codes</span>
      </md-list-item>

      <md-list-item to="/user-activities">
        <md-icon>people_outline</md-icon>
        <span class="md-list-item-text">User Activities</span>
      </md-list-item>

      <md-list-item to="/notifications">
        <md-icon>notifications</md-icon>
        <span class="md-list-item-text">Notifications</span>
      </md-list-item>

      <md-divider class="mt-3"></md-divider>
      <md-subheader>ACCOUNT</md-subheader>

      <md-list-item to="/settings">
        <md-icon>settings</md-icon>
        <span class="md-list-item-text">Settings</span>
      </md-list-item>

      <md-list-item
        @click="
          () => {
            $store.dispatch('auth/logout');
          }
        "
      >
        <md-icon>logout</md-icon>
        <span class="md-list-item-text">Logout</span>
      </md-list-item>
    </md-list>
  </div>
  <!-- End of Sidebar -->
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

// export default {
//   name: "SideDrawer",
// };
@Component
class SideDrawer extends Vue {}

export default SideDrawer;
</script>
<style lang="scss" scoped>
.sidebar-brand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .sidebar-brand-text {
    font-weight: 800;
    font-size: 1rem;
    text-transform: uppercase;
  }
}
</style>
