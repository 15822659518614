import { mapGetters } from "vuex";
import { debounce } from "lodash";
import { MAX_PAGINATION_LIMIT, MAX_PER_PAGE } from "@/constants";

export default {
    name: "UserActivities",

    computed: {
        ...mapGetters("useractivity", [
            "populatedUserActivities",
            "populatedUserActivitiesRaw",
            "populatedUserActivitiesCount",
            "dataTableColumns",
            "loadingFlag",
        ]),
    },

    data: () => ({
        userActivitiesTable: null,
        dataTableElem: null,
        userActivitiesInterval: null,
        searchText: null,
        userActivitiesTableData: [],
        selectedData: [],
        currentPage: 1,
        totalRecords: 0,
        perPage: MAX_PER_PAGE,
        MAX_PAGINATION_LIMIT: MAX_PAGINATION_LIMIT,
    }),
    watch: {
        populatedUserActivitiesRaw() {
            this.userActivitiesTableData = this.populatedUserActivitiesRaw;
            this.totalRecords = this.populatedUserActivitiesCount;
        },
    },
    mounted() {
        this.initDataTable();
    },
    unmounted() {
        clearInterval(this.userActivitiesInterval);
    },
    methods: {
        async initData() {
            await this.$store.dispatch("useractivity/populateUserActivities", {
                draw: 1,
                limit: this.perPage,
                sort_by: "id",
                sort_dir: "desc",
                page: this.currentPage,
            });
            this.userActivitiesTableData = this.populatedUserActivitiesRaw;
            this.totalRecords = this.populatedUserActivitiesCount;
        },
        initDataTable() {
            this.initData();
            this.autorefresh();
        },

        onSelectData(items) {
            this.selectedData = items;
        },

        searchOnTable: debounce(async function() {
            await this.$store.dispatch("useractivity/populateUserActivities", {
              draw: 1,
              limit: this.perPage,
              search: this.searchText,
              page: 1,
            });
          }, 500),

        autorefresh() {
            setInterval(() => {
                this.initData();
            }, 300000);
        },

        checkClickedPage() {
            if (this.loadingFlag) {
                return;
            }

            this.initData();
        },
    },
};
