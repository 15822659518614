import Vue from "vue";
import VueWait from "vue-wait";

import router from "./router";
import store from "./stores";
import App from "./App.vue";

import VueProgressBar from "vue-progressbar";
import VueIziToast from "vue-izitoast";
import VueMaterial from "vue-material";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Fragment from "vue-fragment";

import "./Components";
import "./Filters";
import "bootstrap";

/* Theme stylesheet */
import "@/themes/app.scss";

Vue.config.productionTip = false;
Vue.use(VueWait);
Vue.use(VueIziToast);
Vue.use(VueProgressBar, {
  color: "#ffb733",
  failedColor: "#eb445a",
  thickness: "5px",
});
Vue.use(VueMaterial);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Fragment.Plugin);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  wait: new VueWait({
    useVuex: true,
  }),
}).$mount("#app");

export default app;
