import API from "@/stores/API";
import moment from "moment";
import { set, isEmpty } from "lodash";

const SET_POPULATED_COUPONS = "SET_POPULATED_COUPONS";
const SET_LOADING_FLAG = "SET_LOADING_FLAG";
const CLEAR_LOADING_FLAG = "CLEAR_LOADING_FLAG";

const SET_FORM_ERRORS = "SET_FORM_ERRORS";
const CLEAR_FORM_ERRORS = "CLEAR_FORM_ERRORS";

const INPUT_CHANGED = "INPUT_CHANGED";

/**
 * Vuex state for auth module
 * @type {Object}
 */
const state = {
    populatedCoupons: {},
    coupon: {},
    errors: {},
    loadingFlag: false,
    dataTableColumns: [
        "id",
        "stripe_id",
        "amount_off",
        "percent_off",
        "max_redeem",
        "last_redeem_date",
        "product_type",
        "created_at",
    ],
};

/**
 * Vuex mutations for auth module
 * @type {Object}
 */
const mutations = {
    [SET_POPULATED_COUPONS]: (state, payload) => {
        state.populatedCoupons = payload;
    },

    [SET_FORM_ERRORS]: (state, payload) => {
        state.errors = payload;
    },

    [CLEAR_FORM_ERRORS]: (state) => {
        state.errors = {};
    },

    [SET_LOADING_FLAG]: (state, payload) => {
        state.loadingFlag = payload;
    },

    [CLEAR_LOADING_FLAG]: (state) => {
        state.loadingFlag = {};
    },

    [INPUT_CHANGED]: (state, { key, value }) => {
        const stack = Object.assign({}, state.retailer);
        if (key) set(stack, key, value);
        state.retailer = stack;
    },
};

/**
 * Vuex actions for auth module
 * @type {Object}
 */
const actions = {
    async populateCoupons({ commit }, payload) {
        try {
            commit(SET_LOADING_FLAG, true);
            const { data } = await API.get("/api/coupon", {
                params: payload,
            });
            commit(SET_POPULATED_COUPONS, data);
            commit(SET_LOADING_FLAG, false);
        } catch (e) {
            console.error(e);
        }
    },

    inputChanged({ commit }, payload) {
        commit(INPUT_CHANGED, payload);
    },

    async createCoupon({ commit }, { input, onSuccess, onFailed }) {
        commit(SET_LOADING_FLAG, true);

        try {
            const { data } = await API.post("/api/coupon", { ...input });
            if (onSuccess instanceof Function) onSuccess(data);
        } catch (error) {
            if (error.response.status === 422)
                commit(SET_FORM_ERRORS, error.response.data.errors);
            if (onFailed instanceof Function) onFailed(error);
        }

        commit(SET_LOADING_FLAG, false);
    },
};

/**
 * Vuex getters for coupon module
 * @type {Object}
 */
const getters = {
    populatedCoupons: ({ populatedCoupons }) => {
        const { data } = populatedCoupons;
        const items = [];

        if (!isEmpty(data)) {
            data.forEach((coupon) => {
                items.push([
                    coupon.id,
                    coupon.stripe_id,
                    coupon.amount_off,
                    coupon.percent_off,
                    coupon.max_redeem,
                    coupon.last_redeem_date,
                    coupon.product_type,
                    moment(coupon.created_at).format("dddd, MMMM D, YYYY - h:mm A"),
                ]);
            });
        }

        return items;
    },
    populatedCouponsRaw: ({ populatedCoupons }) => {
        const { data } = populatedCoupons;
        const items = [];

        if (!isEmpty(data)) {
            data.forEach((coupon) => {
                items.push({
                    id: coupon.id,
                    stripe_id: coupon.stripe_id,
                    amount_off: coupon.amount_off,
                    percent_off: coupon.percent_off,
                    max_redeem: coupon.max_redeem,
                    last_redeem_date: coupon.last_redeem_date,
                    product_type: coupon.product_type,
                    created_at: moment(coupon.created_at).format("dddd, MMMM D, YYYY - h:mm A"),
                });
            });
        }

        return items;
    },
    populatedCouponsCount: (state) => state.populatedCoupons.total,
    dataTableColumns: (state) => state.dataTableColumns,
    coupon: (state) => state.coupon,
    errors: (state) => state.errors,
    loadingFlag: (state) => state.loadingFlag,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
