<template>
  <md-table-empty-state :md-label="label" :md-description="description">
    <img src="@/assets/svg/undraw_empty_xct9.svg" class="mt-5"/>
  </md-table-empty-state>
</template>

<script>
export default {
  name: "EmptyTableResult",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
  },
};
</script>
