import { mapGetters } from "vuex";
import { debounce } from "lodash";
import swal from "sweetalert";
import { MAX_PAGINATION_LIMIT, MAX_PER_PAGE } from "@/constants";

export default {
  name: "Retailers",

  computed: {
    ...mapGetters("retailer", [
      "populatedRetailers",
      "populatedRetailersRaw",
      "populatedRetailersCount",
      "dataTableColumns",
      "loadingFlag",
    ]),
  },

  data: () => ({
    retailersTable: null,
    dataTableElem: null,
    retailersInterval: null,
    searchText: null,
    retailersTableData: [],
    selectedData: [],
    currentPage: 1,
    totalRecords: 0,
    perPage: MAX_PER_PAGE,
    MAX_PAGINATION_LIMIT: MAX_PAGINATION_LIMIT,
  }),
  watch: {
    populatedRetailersRaw() {
      this.retailersTableData = this.populatedRetailersRaw;
      this.totalRecords = this.populatedRetailersCount;
    },
  },
  mounted() {
    this.initDataTable();
  },
  unmounted() {
    clearInterval(this.retailersInterval);
  },
  methods: {
    async initData() {
      await this.$store.dispatch("retailer/populateRetailers", {
        draw: 1,
        limit: this.perPage,
        sort_by: "company",
        sort_dir: "asc",
        page: this.currentPage,
      });
      this.retailersTableData = this.populatedRetailersRaw;
      this.totalRecords = this.populatedRetailersCount;
    },
    initDataTable() {
      this.initData();
      this.autorefresh();
    },

    onSelectData(items) {
      this.selectedData = items;
    },

    searchOnTable: debounce(async function() {
      await this.$store.dispatch("retailer/populateRetailers", {
        draw: 1,
        limit: this.perPage,
        search: this.searchText,
        sort_by: "company",
        sort_dir: "asc",
        page: 1,
      });
    }, 500),

    autorefresh() {
      setInterval(() => {
        this.initData();
      }, 300000);
    },

    checkClickedPage() {
      if (this.loadingFlag) {
        return;
      }

      this.initData();
    },

    async onEditRetailer(uid) {
      this.$router.push({
        name: "update-retailer",
        params: {
          retailerID: uid,
        },
        query: {
          action: "edit",
        },
      });
    },

    async onRemoveRetailer(uid) {
      const message = {
        title: "Are you sure you want to remove this retailer?",
        text:
          "The selected Lifebox retailer will be deleted by the system. This action is irreversible.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      };

      if (await swal(message)) {
        await this.$store.dispatch("retailer/removeRetailer", uid);
        this.initData();
        swal(
          "Retailer Deleted Successfully",
          "Retailers list will be updated now, Lifebox Admin."
        );
      }
    },
  },
};
