import { mapGetters } from "vuex";
import { MAX_PAGINATION_LIMIT, MAX_PER_PAGE } from "@/constants";

export default {
    name: "Coupons",

    computed: {
        ...mapGetters("coupon", [
            "populatedCoupons",
            "populatedCouponsRaw",
            "populatedCouponsCount",
            "dataTableColumns",
            "loadingFlag",
        ]),
    },

    data: () => ({
        couponsTable: null,
        dataTableElem: null,
        couponsInterval: null,
        couponsTableData: [],
        selectedData: [],
        currentPage: 1,
        totalRecords: 0,
        perPage: MAX_PER_PAGE,
        MAX_PAGINATION_LIMIT: MAX_PAGINATION_LIMIT,
    }),
    watch: {
        populatedCouponsRaw() {
            this.couponsTableData = this.populatedCouponsRaw;
            this.totalRecords = this.populatedCouponsCount;
        },
    },
    mounted() {
        this.initDataTable();
    },
    unmounted() {
        clearInterval(this.couponsInterval);
    },
    methods: {
        async initData() {
            await this.$store.dispatch("coupon/populateCoupons", {
                draw: 1,
                limit: this.perPage,
                sort_by: "id",
                sort_dir: "asc",
                page: this.currentPage,
            });
            this.couponsTableData = this.populatedCouponsRaw;
            this.totalRecords = this.populatedCouponsCount;
        },
        initDataTable() {
            this.initData();
            this.autorefresh();
        },

        onSelectData(items) {
            this.selectedData = items;
        },

        autorefresh() {
            setInterval(() => {
                this.initData();
            }, 300000);
        },

        checkClickedPage() {
            if (this.loadingFlag) {
                return;
            }

            this.initData();
        },
    },
};
