import { mapGetters } from "vuex";
import swal from "sweetalert";
import { isEmpty } from "lodash";

export default {
  name: "UpdateUser",

  computed: {
    ...mapGetters("user", [
      "user",
      "userStatuses",
      "userTypes",
      "errors",
      "loadingFlag",
    ]),
  },

  data() {
    return {
      input: {
        last_name: "",
        first_name: "",
        username: "",
        mobile: "",
        email: "",
        password: "",
        user_status: "",
        user_type: "",
        id: null,
        showLoading: true,
      },
    };
  },

  watch: {
    user() {
      this.initData();

      if (this.user) {
        this.showLoading = false;
      }
    },
  },
  created() {
    this.showLoading = true;
  },
  mounted() {
    this.showLoading = true;
    this.initData();
  },

  methods: {
    initData() {
      const {
        last_name,
        first_name,
        username,
        mobile,
        email,
        user_status,
        user_type,
        lifebox_email,
        account_number,
        id,
        subscription_status,
      } = this.user;

      this.input = {
        last_name,
        first_name,
        username,
        mobile,
        email,
        user_status,
        user_type,
        lifebox_email,
        account_number,
        id,
        subscription_status,
      };
    },
    isUserSubscribed(status) {
      if (status === "subscribed") {
        return true;
      } else {
        return false;
      }
    },
    isUserVerified(email_verified_at) {
      if (!isEmpty(email_verified_at)) {
        return true;
      } else {
        return false;
      }
    },
    async onSubmit() {
      await this.$store.dispatch("user/updateUser", {
        id: this.user.id,
        input: this.input,
        onSuccess: () => {
          swal(
            "User Edited Successfully",
            "All changes made to the Lifebox user has been saved and applied. Well done.",
            "success"
          );
          this.$router.push({ name: "users" });
        },
        onFailed: () => {
          swal(
            "User Editing Failed",
            "Please fill in the missing / incorrect details and try again.",
            "error"
          );
        },
      });
    },

    async onCancel() {
      const message = {
        title: "Are you sure you want to cancel editing this user?",
        text:
          "All changes made to this user will not be saved after this confirmation.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      };

      if (await swal(message)) {
        await this.$store.dispatch("user/clearUser");
        this.$router.push({ name: "users" });
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const userID = to.params.userID;
      if (userID) {
        next(async (vm) => await vm.$store.dispatch("user/setUser", userID));
      } else {
        next();
      }
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.$store.dispatch("user/clearUser");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteLeave(to, from, next) {
    try {
      await this.$store.dispatch("user/clearUser");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },
};
