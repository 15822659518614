import API from "@/stores/API";
import moment from "moment";
import { set, isEmpty } from "lodash";

const SET_POPULATED_USER_ACTIVITIES = "SET_POPULATED_USER_ACTIVITIES";
const SET_LOADING_FLAG = "SET_LOADING_FLAG";
const CLEAR_LOADING_FLAG = "CLEAR_LOADING_FLAG";

const SET_FORM_ERRORS = "SET_FORM_ERRORS";
const CLEAR_FORM_ERRORS = "CLEAR_FORM_ERRORS";

const INPUT_CHANGED = "INPUT_CHANGED";

/**
 * Vuex state for auth module
 * @type {Object}
 */
const state = {
    populatedUserActivities: {},
    userActivity: {},
    errors: {},
    loadingFlag: false,
    dataTableColumns: [
        "id",
        "user_id",
        "activity",
        "created_at",
    ],
};

/**
 * Vuex mutations for auth module
 * @type {Object}
 */
const mutations = {
    [SET_POPULATED_USER_ACTIVITIES]: (state, payload) => {
        state.populatedUserActivities = payload;
    },

    [SET_FORM_ERRORS]: (state, payload) => {
        state.errors = payload;
    },

    [CLEAR_FORM_ERRORS]: (state) => {
        state.errors = {};
    },

    [SET_LOADING_FLAG]: (state, payload) => {
        state.loadingFlag = payload;
    },

    [CLEAR_LOADING_FLAG]: (state) => {
        state.loadingFlag = {};
    },

    [INPUT_CHANGED]: (state, { key, value }) => {
        const stack = Object.assign({}, state.retailer);
        if (key) set(stack, key, value);
        state.retailer = stack;
    },
};

/**
 * Vuex actions for auth module
 * @type {Object}
 */
const actions = {
    async populateUserActivities({ commit }, payload) {
        try {
            commit(SET_LOADING_FLAG, true);
            const { data } = await API.get("/api/user-activity", {
                params: payload,
            });
            commit(SET_POPULATED_USER_ACTIVITIES, data);
            commit(SET_LOADING_FLAG, false);
        } catch (e) {
            console.error(e);
        }
    },

    inputChanged({ commit }, payload) {
        commit(INPUT_CHANGED, payload);
    }
};

/**
 * Vuex getters for userActivity module
 * @type {Object}
 */
const getters = {
    populatedUserActivities: ({ populatedUserActivities }) => {
        const { data } = populatedUserActivities;
        const items = [];

        if (!isEmpty(data)) {
            data.forEach((userActivity) => {
                items.push([
                    userActivity.id,
                    userActivity.user_id,
                    userActivity.activity,
                    moment(userActivity.created_at).format("dddd, MMMM D, YYYY - h:mm A"),
                ]);
            });
        }

        return items;
    },
    populatedUserActivitiesRaw: ({ populatedUserActivities }) => {
        const { data } = populatedUserActivities;
        const items = [];

        if (!isEmpty(data)) {
            data.forEach((userActivity) => {
                items.push({
                    id: userActivity.id,
                    user_id: userActivity.user_id,
                    activity: userActivity.activity,
                    created_at: moment(userActivity.created_at).format("dddd, MMMM D, YYYY - h:mm A"),
                });
            });
        }

        return items;
    },
    populatedUserActivitiesCount: (state) => state.populatedUserActivities.total,
    dataTableColumns: (state) => state.dataTableColumns,
    userActivity: (state) => state.userActivity,
    errors: (state) => state.errors,
    loadingFlag: (state) => state.loadingFlag,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
