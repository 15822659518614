import API from "@/stores/API";
import moment from "moment";
import { set, isEmpty } from "lodash";

const SET_POPULATED_RETAILERS = "SET_POPULATED_RETAILERS";

const SET_RETAILER = "SET_RETAILER";
const CLEAR_RETAILER = "CLEAR_RETAILER";

const SET_LOADING_FLAG = "SET_LOADING_FLAG";
const CLEAR_LOADING_FLAG = "CLEAR_LOADING_FLAG";

const SET_FORM_ERRORS = "SET_FORM_ERRORS";
const CLEAR_FORM_ERRORS = "CLEAR_FORM_ERRORS";

const INPUT_CHANGED = "INPUT_CHANGED";

/**
 * Vuex state for auth module
 * @type {Object}
 */
const state = {
  populatedRetailers: {},
  retailer: {},
  errors: {},
  loadingFlag: false,
  dataTableColumns: [
    "company",
    "status",
    "created_at",
  ],
};

/**
 * Vuex mutations for auth module
 * @type {Object}
 */
const mutations = {
  [SET_POPULATED_RETAILERS]: (state, payload) => {
    state.populatedRetailers = payload;
  },

  [SET_RETAILER]: (state, payload) => {
    state.retailer = payload;
  },

  [CLEAR_RETAILER]: (state) => {
    state.retailer = {};
  },

  [SET_FORM_ERRORS]: (state, payload) => {
    state.errors = payload;
  },

  [CLEAR_FORM_ERRORS]: (state) => {
    state.errors = {};
  },

  [SET_LOADING_FLAG]: (state, payload) => {
    state.loadingFlag = payload;
  },

  [CLEAR_LOADING_FLAG]: (state) => {
    state.loadingFlag = {};
  },

  [INPUT_CHANGED]: (state, { key, value }) => {
    const stack = Object.assign({}, state.retailer);
    if (key) set(stack, key, value);
    state.retailer = stack;
  },
};

/**
 * Vuex actions for auth module
 * @type {Object}
 */
const actions = {
  async populateRetailers({ commit }, payload) {
    try {
      commit(SET_LOADING_FLAG, true);
      const { data } = await API.get("/api/retailer", {
        params: payload,
      });
      commit(SET_POPULATED_RETAILERS, data);
      commit(SET_LOADING_FLAG, false);
    } catch (e) {
      console.error(e);
    }
  },

  inputChanged({ commit }, payload) {
    commit(INPUT_CHANGED, payload);
  },

  async setRetailer({ commit }, retailerID) {
    try {
      const { data } = await API.get(`/api/retailer/${retailerID}`);
      commit(SET_RETAILER, data);
    } catch (e) {
      console.error(e);
    }
  },

  async removeRetailer({ commit }, retailerID) {
    try {
      await API.delete(`/api/retailer/${retailerID}`);
      commit(CLEAR_RETAILER);
    } catch (e) {
      console.error(e);
    }
  },

  async clearRetailer({ commit }) {
    commit(CLEAR_RETAILER);
    commit(CLEAR_FORM_ERRORS);
  },

  async updateRetailer({ commit }, { id, input, onSuccess, onFailed }) {
    commit(SET_LOADING_FLAG, true);

    try {
      const { data } = await API.patch(`/api/retailer/${id}`, {
        ...input,
      });
      if (data) commit(SET_RETAILER, data);
      if (onSuccess instanceof Function) onSuccess(data);
    } catch (error) {
      if (error.response.status === 422)
        commit(SET_FORM_ERRORS, error.response.data.errors);
      if (onFailed instanceof Function) onFailed(error);
    }

    commit(SET_LOADING_FLAG, false);
  },

  async createRetailer({ commit }, { input, onSuccess, onFailed }) {
    commit(SET_LOADING_FLAG, true);

    try {
      const { data } = await API.post(`/api/retailer`, { ...input });
      if (data) commit(SET_RETAILER, data);
      if (onSuccess instanceof Function) onSuccess(data);
    } catch (error) {
      if (error.response.status === 422)
        commit(SET_FORM_ERRORS, error.response.data.errors);
      if (onFailed instanceof Function) onFailed(error);
    }

    commit(SET_LOADING_FLAG, false);
  },
};

/**
 * Vuex getters for auth module
 * @type {Object}
 */
const getters = {
  populatedRetailers: ({ populatedRetailers }) => {
    const { data } = populatedRetailers;
    const items = [];

    if (!isEmpty(data)) {
      data.forEach((retailer) => {
        items.push([
          retailer.company,
          retailer.status,
          moment(retailer.created_at).format("dddd, MMMM D, YYYY - h:mm A"),
          retailer.id,
        ]);
      });
    }

    return items;
  },
  populatedRetailersRaw: ({ populatedRetailers }) => {
    const { data } = populatedRetailers;
    const items = [];

    if (!isEmpty(data)) {
      data.forEach((retailer) => {
        items.push({
          company: retailer.company,
          status: retailer.status,
          created_at: moment(retailer.created_at).format(
            "dddd, MMMM D, YYYY - h:mm A"
          ),
          id: retailer.id,
        });
      });
    }

    return items;
  },
  populatedRetailersCount: (state) => state.populatedRetailers.total,
  dataTableColumns: (state) => state.dataTableColumns,
  retailer: (state) => state.retailer,
  errors: (state) => state.errors,
  loadingFlag: (state) => state.loadingFlag,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
