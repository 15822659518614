import { mapGetters } from "vuex";
import swal from "sweetalert";
import { debounce, isEmpty } from "lodash";
import { MAX_PAGINATION_LIMIT, MAX_PER_PAGE } from "@/constants";

export default {
  name: "Users",

  computed: {
    ...mapGetters("user", [
      "populatedUsers",
      "populatedUsersRaw",
      "populatedUsersCount",
      "dataTableColumns",
      "impersonationLink",
      "loadingFlag",
    ]),
  },

  watch: {
    populatedUsersRaw() {
      this.usersTableData = this.populatedUsersRaw;
      this.totalRecords = this.populatedUsersCount;
    },
  },

  data: () => ({
    currentPage: 1,
    perPage: MAX_PER_PAGE,
    dataTableElem: null,
    usersTable: null,
    selectedUsers: [],
    searchText: null,
    usersTableData: [],
    totalRecords: 0,
    userInterval: null,
    MAX_PAGINATION_LIMIT: MAX_PAGINATION_LIMIT,
  }),
  mounted() {
    this.initDataTable();
  },
  unmounted() {
    clearInterval(this.userInterval);
  },
  methods: {
    isUserSubscribed(status) {
      if (status === "subscribed") {
        return true;
      } else {
        return false;
      }
    },
    isUserActive(status) {
      if (status === "active") {
        return true;
      } else {
        return false;
      }
    },
    async initData() {
      await this.$store.dispatch("user/populateUsers", {
        draw: 1,
        limit: this.perPage,
        sort_by: "first_name",
        sort_dir: "asc",
        page: this.currentPage,
      });
      this.usersTableData = this.populatedUsersRaw;
      this.totalRecords = this.populatedUsersCount;
    },

    onSelectUsers(items) {
      this.selectedUsers = items;
    },

    searchOnTable: debounce(async function() {
      await this.$store.dispatch("user/populateUsers", {
        draw: 1,
        limit: this.perPage,
        search: this.searchText,
        sort_by: "first_name",
        sort_dir: "asc",
        page: 1,
      });
    }, 500),

    initDataTable() {
      this.initData();
      this.autorefresh();
    },

    autorefresh() {
      this.userInterval = setInterval(() => {
        this.initData();
      }, 300000);
    },

    checkClickedPage() {
      if (this.loadingFlag) {
        return;
      }

      this.initData();
    },

    async verifyUser(user) {
      const message = {
        title: "Confirm to verify this user?",
        text:
          "The selected Lifebox user account will be verified by the system. This action is irreversible.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      };

      if (!isEmpty(user.email_verified_at)) {
        return swal({
          title: "User already verified!",
          text: "The selected Lifebox user account already verified.",
          icon: "warning",
        });
      }

      if (await swal(message)) {
        await this.$store.dispatch("user/updateUser", {
          id: user.id,
          input: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            user_type: user.user_type,
            lifebox_email: user.lifebox_email,
            user_status: "active",
          },
          onSuccess: () => {
            swal(
              "User Edited Successfully",
              "All changes made to the Lifebox user has been saved and applied. Well done.",
              "success"
            );
            this.$router.push({ name: "users" });
          },
          onFailed: () => {
            swal(
              "User Editing Failed",
              "Please fill in the missing / incorrect details and try again.",
              "error"
            );
          },
        });
        this.initData();
      }
    },

    async onImpersonateUser(uid) {
      console.log("Impersonated User Is: " + uid);
      await this.$store.dispatch("user/impersonateUser", { id: uid });
      window.location = this.impersonationLink;
    },

    async onEditUser(uid) {
      this.$router.push({
        name: "update-user",
        params: {
          userID: uid,
        },
        query: {
          action: "edit",
        },
      });
    },

    async onRemoveUser(uid) {
      const message = {
        title: "Are you sure you want to remove this user?",
        text:
          "The selected Lifebox user account will be deleted by the system. This action is irreversible.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      };

      if (await swal(message)) {
        await this.$store.dispatch("user/removeUser", uid);
        this.initData();
        swal(
          "User Deleted Successfully",
          "Users list will be updated now, Lifebox Admin."
        );
      }
    },

    async onNotifyUser(uid) {
      this.$router.push({
        name: "notify-user",
        params: {
          userID: uid,
        },
      });
    },
  },
};
