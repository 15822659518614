import { mapGetters } from "vuex";
import swal from "sweetalert";

export default {
  name: "CreateUser",

  computed: {
    ...mapGetters("user", [
      "user",
      "userStatuses",
      "userTypes",
      "errors",
      "loadingFlag",
    ]),
  },
  data() {
    return {
      input: {
        last_name: "",
        first_name: "",
        username: "",
        mobile: "",
        email: "",
        password: "",
        user_status: "",
        user_type: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      await this.$store.dispatch("user/createUser", {
        input: this.input,
        onSuccess: () => {
          swal(
            "User Created Successfully",
            "Congratulations for another customer, Lifebox Admin.",
            "success"
          );
          this.$router.push({ name: "users" });
        },
        onFailed: () => {
          swal(
            "User Creation Failed",
            "Please fill in the missing / incorrect details and try again.",
            "error"
          );
        },
      });
    },

    async onCancel() {
      const message = {
        title: "Are you sure you want to cancel creating this user?",
        text:
          "All details put to this user account will not be saved after this confirmation.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      };

      if (await swal(message)) {
        await this.$store.dispatch("user/clearUser");
        this.$router.push({ name: "users" });
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const userID = to.params.userID;
      if (userID) {
        next(async (vm) => await vm.$store.dispatch("user/setUser", userID));
      } else {
        next();
      }
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.$store.dispatch("user/clearUser");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteLeave(to, from, next) {
    try {
      await this.$store.dispatch("user/clearUser");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },
};
