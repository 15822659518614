import { mapGetters } from "vuex";
import { MAX_PAGINATION_LIMIT, MAX_PER_PAGE } from "@/constants";

export default {
    name: "VoucherCodes",

    computed: {
        ...mapGetters("vouchercode", [
            "populatedVoucherCodes",
            "populatedVoucherCodesRaw",
            "populatedVoucherCodesCount",
            "dataTableColumns",
            "loadingFlag",
        ]),
    },

    data: () => ({
        voucherCodesTable: null,
        dataTableElem: null,
        voucherCodesInterval: null,
        voucherCodesTableData: [],
        selectedData: [],
        currentPage: 1,
        totalRecords: 0,
        perPage: MAX_PER_PAGE,
        MAX_PAGINATION_LIMIT: MAX_PAGINATION_LIMIT,
    }),
    watch: {
        populatedVoucherCodesRaw() {
            this.voucherCodesTableData = this.populatedVoucherCodesRaw;
            this.totalRecords = this.populatedVoucherCodesCount;
        },
    },
    mounted() {
        this.initDataTable();
    },
    unmounted() {
        clearInterval(this.voucherCodesInterval);
    },
    methods: {
        async initData() {
            await this.$store.dispatch("vouchercode/populateVoucherCodes", {
                draw: 1,
                limit: this.perPage,
                sort_by: "id",
                sort_dir: "asc",
                page: this.currentPage,
            });
            this.voucherCodesTableData = this.populatedVoucherCodesRaw;
            this.totalRecords = this.populatedVoucherCodesCount;
        },
        initDataTable() {
            this.initData();
            this.autorefresh();
        },

        onSelectData(items) {
            this.selectedData = items;
        },

        autorefresh() {
            setInterval(() => {
                this.initData();
            }, 300000);
        },

        checkClickedPage() {
            if (this.loadingFlag) {
                return;
            }

            this.initData();
        },
    },
};
