<template>
  <md-field v-bind:class="{ clearable, 'md-invalid': errors && errors.length }">
    <label
      :for="id"
      v-if="label"
      :class="[{ 'font-weight-bold': labelBold }]"
      >{{ label }}</label
    >

    <div class="input-group mb-2" v-if="inputGroupOne || inputGroupTwo">
      <div class="input-group-prepend" v-if="inputGroupOne">
        <span
          :class="[
            'input-group-text',
            { 'font-weight-bold': inputGroupFontBold },
          ]"
          >{{ inputGroupOne }}</span
        >
      </div>

      <input
        @input.native="onInput"
        :value="value"
        :type="type"
        :class="[
          classes,
          {
            'is-invalid': errors.length,
            filled: value,
            'hide-invalid-icon': hideInvalidIcon,
          },
        ]"
        :placeholder="placeholder"
        :id="id"
        :readonly="readonly"
        :autocomplete="autocomplete || 'nope'"
        :step="numFormat"
        v-bind="{ ...$attrs, ...$props }"
        min="0"
      />

      <div class="input-group-append" v-if="inputGroupTwo">
        <span
          :class="[
            'input-group-text',
            { 'font-weight-bold': inputGroupFontBold },
          ]"
          >{{ inputGroupTwo }}</span
        >
      </div>
      <slot name="hint"></slot>
      <span class="md-error">
        {{ errors.length ? errors[0] : null }}
      </span>
    </div>

    <template v-else>
      <md-input
        v-bind="{ ...$attrs, ...$props }"
        @input.native="onInput"
        :value="value"
        :type="type"
        :class="[
          classes,
          {
            'is-invalid': errors.length,
            filled: value,
            'hide-invalid-icon': hideInvalidIcon,
          },
        ]"
        :placeholder="placeholder"
        :id="id"
        :readonly="readonly"
        :autocomplete="autocomplete || 'nope'"
        :step="numFormat"
        min="0"
      ></md-input>

      <slot name="hint"></slot>
      <span class="md-error">
        {{ errors.length ? errors[0] : null }}
      </span>
    </template>

    <i class="fa fa-times" v-if="clearable" @click="$emit('clear')"></i>
  </md-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    hideInvalidIcon: {
      type: Boolean,
      default: false,
    },

    value: {
      validator(v) {
        return v === null || typeof v === "number" || typeof v === "string";
      },
    },

    type: {
      type: String,
      default: "text",
    },

    placeholder: {
      type: String,
    },

    errors: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: null,
    },

    classes: {
      type: String,
      default: "",
    },

    inputGroupOne: {
      type: String,
      default: "",
    },

    inputGroupTwo: {
      type: String,
      default: "",
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: Boolean,
      default: false,
    },

    numFormat: {
      type: String,
      default: "1",
    },

    labelBold: {
      type: Boolean,
      default: false,
    },

    inputGroupFontBold: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    id: null,
  }),

  methods: {
    onInput(v) {
      if (v.target) {
        this.$emit("input", v.target.value);
        this.$emit("change", v.target.value);
        this.$emit("update", v.target.value);
      }
    },

    focus() {
      this.$el.querySelector("input").focus();
    },
  },

  beforeMount() {
    this.id = Math.random()
      .toString(36)
      .substring(length);
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  i.fa {
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    padding: 0 10px;
    line-height: 36px;
  }

  &.clearable .form-control {
    padding-right: 28px;
  }

  .form-control {
    &.hide-invalid-icon {
      &.is-invalid,
      &:invalid {
        background: white;
      }
    }
  }
}
</style>
