import { mapGetters } from "vuex";
import swal from "sweetalert";

export default {
  name: "UpdateRetailer",

  computed: {
    ...mapGetters("retailer", ["retailer", "errors", "loadingFlag"]),
  },

  data() {
    return {
      input: {
        company: "",
        status: "",
        id: null,
        showLoading: true,
      },
    };
  },
  watch: {
    retailer() {
      this.initData();
      if (this.retailer) {
        this.showLoading = false;
      }
    },
  },
  mounted() {
    this.showLoading = true;
    this.initData();
  },
  methods: {
    initData() {
      const {
        company,
        status,
        id,
      } = this.retailer;
      this.input = {
        company,
        status,
        id,
      };
    },
    async onSubmit() {
      await this.$store.dispatch("retailer/updateRetailer", {
        id: this.retailer.id,
        input: this.input,
        onSuccess: () => {
          swal(
            "Retailer Edited Successfully",
            "All changes made to the Lifebox retailer has been saved and applied. Well done.",
            "success"
          );
          this.$router.push({ name: "retailers" });
        },
        onFailed: () => {
          swal(
            "Retailer Editing Failed",
            "Please fill in the missing / incorrect details and try again.",
            "error",
          );
        },
      });
    },

    async onCancel() {
      const message = {
        title: "Are you sure you want to cancel editing this retailer?",
        text:
          "All changes made to this retailer will not be saved after this confirmation.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      };

      if (await swal(message)) {
        await this.$store.dispatch("retailer/clearRetailer");
        this.$router.push({ name: "retailers" });
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const retailerID = to.params.retailerID;
      if (retailerID) {
        next(async (vm) => {
          await vm.$store.dispatch("retailer/setRetailer", retailerID);
        });
      } else {
        next();
      }
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.$store.dispatch("retailer/clearRetailer");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },

  async beforeRouteLeave(to, from, next) {
    try {
      await this.$store.dispatch("retailer/clearRetailer");
      next();
    } catch (e) {
      next({ name: "home" });
    }
  },
};
