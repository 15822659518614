import { render, staticRenderFns } from "./Users.vue?vue&type=template&id=24fc9fde&scoped=true&"
import script from "./Users.js?vue&type=script&lang=js&"
export * from "./Users.js?vue&type=script&lang=js&"
import style0 from "./Users.scss?vue&type=style&index=0&id=24fc9fde&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24fc9fde",
  null
  
)

export default component.exports