import Vue from "vue";

// Custom Layouts
Vue.component("AuthLayout", require("@/shared/layouts/Auth").default);
Vue.component("GuestLayout", require("@/shared/layouts/Guest").default);

// Custom Vue components
Vue.component("NavBar", require("@/shared/NavBar").default);
Vue.component("ToolbarHeader", require("@/shared/ToolbarHeader").default);
Vue.component("SideBarMenu", require("@/shared/SideBarMenu").default);
Vue.component("SideDrawer", require("@/shared/SideDrawer").default);

Vue.component("Accordion", require("@/components/Accordion").default);
Vue.component("Button", require("@/components/Button").default);
Vue.component("Clock", require("@/components/Clock").default);
Vue.component("Calendar", require("@/components/Calendar").default);
Vue.component("DataTable", require("@/components/DataTable").default);
Vue.component("DatePicker", require("@/components/DatePicker").default);
Vue.component("FilePreview", require("@/components/FilePreview").default);
Vue.component("TextField", require("@/components/TextField").default);
Vue.component("TextAreaField", require("@/components/TextAreaField").default);
Vue.component("TimePicker", require("@/components/TimePicker").default);
Vue.component("Window", require("@/components/Window").default);
Vue.component("ImageInput", require("@/components/ImageInput").default);
Vue.component("FileInput", require("@/components/FileInput").default);
Vue.component("OverviewCard", require("@/components/OverviewCard").default);

Vue.component("Tabs", require("@/components/NavTab/Tabs").default);
Vue.component("Tab", require("@/components/NavTab/Tab").default);
Vue.component("TabPanels", require("@/components/NavTab/TabPanels").default);
Vue.component("TabPanel", require("@/components/NavTab/TabPanel").default);

Vue.component(
  "EmptyTableResult",
  require("@/components/EmptyTableResult").default
);
Vue.component("EmptyResult", require("@/components/EmptyResult").default);
Vue.component("LoadingSpinner", require("@/components/LoadingSpinner").default);
